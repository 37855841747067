import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import { Container, Row, Col } from "react-bootstrap"
import "../styles/global.scss"
import arrowIMG from "../images/arrow.svg"
import LogoFixedMobile from "../components/LogoFixedMobile"

import { GatsbySeo } from "gatsby-plugin-next-seo"

const nowContent = {
  title: "What Tyler is doing now",
}

const favoritesArray = [
  {
    title: "What I am doing now:",
    items: [
      "1. Living in Little Rock, Arkansas",
      "2. Leading a Marketing and Media team",
      "3. Re-discovering mountain biking",
      "4. Finding the right inputs to drive confidence in the future",
      "5. Hosting the SchoolCEO podcast",
    ],
  },
  {
    title: "What is on my mind:",
    items: [
      "Getting outside on my own and with my family",
      "Fatal flaws (those weaknesses we know of but can't shake)",
      "Relying on close friends",
      "Speaking less and listening more",
      "Practicing calm and clean work",
    ],
  },
]

const onMyMindLinks = {
  title: "What I'm reading/listening/watching:",
  items: [
    {
      link: "https://bookshop.org/p/books/tomorrow-and-tomorrow-and-tomorrow-gabrielle-zevin/17502475",
      text: "Tomorrow Tomorrow Tomorrow",
      type: "(book)",
    },
    {
      link: "https://open.spotify.com/artist/0Ya43ZKWHTKkAbkoJJkwIB?si=s12PbGv_TtGcnJnFOTjYdg",
      text: "The Wombats",
      type: "(artist)",
    },
    {
      link: "https://www.amazon.com/Lead-Yourself-First-Inspiring-Leadership/dp/1632866315",
      text: "Lead Yourself First",
      type: "(book)",
    },
    {
      link: "https://www.amazon.com/Personal-MBA-Master-Art-Business/dp/1591845572",
      text: "The Personal MBA",
      type: "(book)",
    },
    {
      link: "https://open.spotify.com/episode/32n415RDigeSzDkiNsQg1Y?si=ceb9a2267138408f",
      text: "Peter Attia's Drive podcast: Training for The Centenarian Decathalon",
      type: "(podcast)",
    },
  ],
}

const letsTalk = {
  title: "Let's talk:",
  content:
    "I would like to hear your story and know who you are and what you’re working on. Something I miss most about my time living in NYC is making connections professional and personal. The easiest way to connect is by picking a time here that works for you.",
  linkText: "Get in touch.",
}

class Now extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }

    this.handleClose = () => this.setState({ show: false })
    this.handleShow = () => this.setState({ show: true })
  }

  componentDidMount() {}

  render() {
    return (
      <Layout location={this.props.location}>
        <GatsbySeo
            title="Tyler Vawser | What He's Doing Now" //// this is the title of the window and seo title
          description="What is on Tyler's mind and what he's working on now" // seo description
          canonical="https://www.tylervawser.com/now"
          keywords="tylervawser,tyler vawser,nowpage," // keywords list
          openGraph={{
            url: "https://www.tylervawser.com/now",
            title: "About Tyler Vawser",
            description: "What Tyler is doing now",
            images: [
              {
                url: "../src/images/OGtylervawser.jpg",
                width: 1200,
                height: 1200,
                alt: "Tyler Vawser",
              },
            ],
            site_name: "TylerVawser",
          }}
          twitter={{
            handle: "@tvaw",
            site: "@site",
            cardType: "summary_large_image",
          }}
        />
        
        <LogoFixedMobile />
        <div>
          <Row>
            <Col
              xl={8}
              style={{
                color: "var(--textNormal)",
                transitionTimingFunction: `cubic-bezier(0.25, 0.1, 0.25, 1)`,
                transition: `0.4s`,
                textAlign: `center`,
                margin: `auto`,
                maxWidth: `90vw`,
                padding: `-20vh 10vw 0 10vw`,
                fontSize: `calc(20px + 4vw)`,
              }}
              className="wider wsans w-medium pb-0 mb-0 line-height-1 mt-0 w-semibold align-center"
            >
              {nowContent.title}{" "}
            </Col>
          </Row>
          <Row className="mt-5 py-4">
            <Col
              style={{
                color: "var(--textNormal)",
                transitionTimingFunction: `cubic-bezier(0.25, 0.1, 0.25, 1)`,
                transition: `0.4s`,
                textAlign: `center`,
                margin: `auto`,
                maxWidth: `90vw`,
                padding: `10vh 10vw 0 10vw`,
                fontSize: `calc(14px + .7vw)`,
              }}
              className="roboto w-medium"
            >
              Check it out here
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                color: "var(--textNormal)",
                textAlign: `center`,
                margin: `auto`,
                maxWidth: `90vw`,
                padding: `0 10vw 0 10vw`,
                fontSize: `calc(10px + .7vw)`,
              }}
              className="roboto w-medium"
            >
              <img src={arrowIMG} alt="Arrow down"></img>
            </Col>
          </Row>
        </div>
        <Container fluid>
          <Row className="d-flex justify-content-center pb-5">
            <Col xl={{ span: 7 }} xs={10} sm={10} className="mb-5 pb-5 pt-5">
              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                Right now, I spend my working hours running a marketing team that publishes a magazine, hosts a conference for K-12 leaders around the country, and supports a EdTech company at {" "}
                <a
                  href="https://www.apptegy.com/"
                  style={{ color: `var(--skyblue)` }}
                  target="_blank"
                  className="fancy-link-footer"
                >
                  Apptegy
                </a>
                . Much of my day is spent thinking about how to lead myself, my team, and how organizational culture impacts everything from my own work to the school leaders' work who we support.
              </p>
    <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                In 2024, I've spent much of the year prioritizing the habits, values, and relationships that outlast deadlines, projects, and work. I've re-discovered mountain biking after a 20 year hiatus, found my running legs again, and learned to chill out in a conversation and simply enjoy the company of others.
              </p>

              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                Last year, in 2023, I focused my personal life on confronting bad habits, character flaws, and "the weaknesses that haunt" me. I call these my (our) fatal flaws. There's an ongoing reminder on my calendar that pops up every few weeks with this quote, "A fear that the weaknesses that have dogged you are not just temporary challenges to cope with, but a permanent part of who you are." 
              </p>

              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                At this stage in life, I'm learning and struggling to slow down, let things be as they are, and reliquish (or acknowledge that I never had) control so that I can drive the right inputs on what does matter.
              </p>

              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                The most important part of my life now and for the next 17 years
                are my kids. Spending time with them, drawing trash trucks, playing Five Crowns, teaching them to love
                reading, and teaching them how to be self-aware are the highlight of
                every day. As much as I love working and driving towards a goal,
                I want to use the energy and momentum I build at work to pour
                into my kids. {" "}
              </p>

              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                <em>
                  This is a Now page. Now pages are inspired by
                  <a
                    href="https://nownownow.com/about"
                    style={{ color: `var(--skyblue)` }}
                    target="_blank"
                    className="fancy-link-footer"
                  >
                    {" "}
                    Derek Sivers
                  </a>
                  . It is similar to an about page, but it tells you what I am
                  doing now (how I am spending my time and what I am focused
                  on)— not just what I have done in the past. This entire site
                  is inspired with that in mind.
                </em>
              </p>

              <p
                style={{
                  fontSize: `20px`,
                  color: "var(--textNormal)",
                }}
              >
                Last updated: August 5, 2024
              </p>
              <p
                style={{
                  color: "var(--textNormal)",
                }}
              >
                {nowContent.p}
              </p>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{
            background: "var(--gradient-background)",
            width: `110vw`,
            margin: `6vh -10vw 10vh -10vw`,
          }}
        >
          <Row className="d-flex justify-content-center pb-5 pt-4 ">
            <Col
              xs={{ span: 9 }}
              sm={{ span: 9 }}
              md={7}
              lg={7}
              xl={{ span: 6, offset: 1 }}
              className="mb-5 pb-5"
            >
              {favoritesArray.map(favoriteSet => {
                return (
                  <div>
                    <Row className="d-flex align-items-baseline">
                      <span className="px-2 mt-4 wsans w-regular fav-title">
                        {favoriteSet.title}
                      </span>
                    </Row>
                    {favoriteSet.items.map(item => {
                      return (
                        <Row className="d-flex ml-2 ml-md-4 w-100 w-md-75">
                          <p
                            className="py-1 fancy-link-footer text-light  w-semibold mb-0 "
                            style={{
                              fontSize: `20px`,
                            }}
                          >
                            {item}
                          </p>
                        </Row>
                      )
                    })}
                  </div>
                )
              })}
              <Row className="d-flex align-items-baseline">
                <div className="px-2 mt-4 wsans w-regular fav-title">
                  {onMyMindLinks.title}
                </div>
              </Row>
              {onMyMindLinks.items.map(mind => (
                <Row
                  className="pr-md-5 text-left pl-3"
                  style={{ fontSize: `20px` }}
                >
                  {" "}
                  <Row className="d-flex ml-2 ml-md-4 w-100 w-md-75">
                    <a
                      style={{ color: `#add5ff` }}
                      className="py-1 fancy-link-footer text-light  w-semibold mb-0 "
                      href={mind.link}
                      target="_blank"
                    >
                      {mind.text} {mind.type}
                    </a>
                  </Row>
                </Row>
              ))}
              <Row className="d-flex align-items-baseline">
                <span className="px-2 mt-4 wsans w-regular fav-title">
                  {letsTalk.title}
                </span>
              </Row>
              <Row className="d-flex ml-2 ml-md-3 w-100 w-md-50">
                <p
                  className=" text-light  w-semibold mb-0 py-2"
                  style={{ fontSize: `20px` }}
                >
                  {letsTalk.content}
                </p>
              </Row>
              <Row className="ml-md-0 ml-0 mb-2">
                <Link
                  style={{ color: `#add5ff`, fontSize: `20px` }}
                  className="fancy-link-footer d-flex ml-md-3 "
                  to="/connect/"
                >
                  {letsTalk.linkText}
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Now
